<template>
    <div>
         <a-spin v-if="isLoadingTags"></a-spin>
         <loading-screen :is-loading="isDeletingTag"></loading-screen>

         <div v-if="!isLoadingTags">

            <div v-if="tags.length === 0">
                <a-badge status="default" text="No tags" />
            </div>
            <div v-else>
                <div v-for="tag in tags" :key="tag.id" class="tag-wrapper">
                    <!-- <a-badge status="default" :text="tag.description" /> -->
                    <a-tag color="blue" closable @close.prevent="askToRemoveTag(tag)">{{ tag.description }}</a-tag>
                </div>
            </div>

            <div class="add-tag-wrapper">
                <a-button @click="openModal" type="primary" size="small">Add Tag</a-button>
            </div>

            <a-modal v-model="showAddTagModal"
                title="Add Tag" @ok="addTag">

                <div v-if="!showCustomTag">
                    <a-select style="width: 100%"
                    size="large"
                    v-model="selectedTagToAdd" :options="addableTags" placeholder="Select a tag" />

                    <a-button @click="showCustomTag = true" type="link">+ Custom Tag</a-button>
                </div>

                <div v-if="showCustomTag">
                    <a-input size="large" v-model="customTagString" placeholder="Enter a custom tag" />

                    <a-button @click="showCustomTag = false" type="link">Back to preset tags</a-button>
                </div>
           


            </a-modal>

            <a-modal v-model="showRemoveTagModal"
                title="Remove Tag"
                @ok="removeTag"
                okText="Yes, remove"
                cancelText="Cancel">
                <p>Are you sure you want to remove the tag "{{ tagToRemove?.description }}"?</p>
            </a-modal>

         </div>
    </div>
</template>

<script>
import tags from '../../../../api/tags';
import LoadingScreen from '../../../../components/LoadingScreen.vue';

export default {
    props: ['tenantId', 'incidentId', 'orgId'],

    components: { LoadingScreen },

    data() {
        return {
            tags: [],
            isLoadingTags: false,

            addableTags: [],
            isLoadingAddableTags: false,

            showAddTagModal: false,

            selectedTagToAdd: null,

            showCustomTag: null,
            customTagString: '',

            tagToRemove: null,
            showRemoveTagModal: false,
            isDeletingTag: false
        }
    },

    created() {
        this.getTags();
    },

    watch: {
        incidentId() {
            this.getTags();
        }
    },

    methods: {
        getTags() {
            this.isLoadingTags = true;
            this.tags = [];
            tags.getTagsForGroup(
                this.tenantId,
                this.orgId,
                'Incident',
                this.incidentId
            ).then((response) => {
                this.tags = response.data;
            }).catch(e => {
                console.log(e);
            }).finally(() => {
                this.isLoadingTags = false;
            });
        },

        openModal() {
            this.loadAddableTags();
            this.selectedTagToAdd = null;
            this.showAddTagModal = true;
        },

        async loadAddableTags() {
            this.isLoadingAddableTags = true;
            this.addableTags = [];

            let tagProviders = [];
            try {
                tagProviders = await tags.getTagProviders(this.tenantId, this.orgId).then(r => r.data);
            } catch (e) {
                console.log(e);
                tagProviders = [];
            }

            tags.getTags(this.tenantId, this.orgId, tagProviders[0].id).then((response) => {
                this.addableTags = response.data;
            }).catch(e => {
                console.log(e);
            }).finally(() => {
                this.isLoadingAddableTags = false;
            });
        },

        async addTag() {
            if (!this.showCustomTag && !this.selectedTagToAdd) {
                alert('Please select a tag');
                return;
            }

            if (this.showCustomTag && !this.customTagString) {
                alert('Please enter a custom tag');
                return;
            }

            let uniqId = 'd-' + new Date().getTime();

            await tags.addTagToGroup(
                this.tenantId,
                this.orgId,
                'Incident',
                this.incidentId,
                'Custom',
                'Custom',
                uniqId,
                this.customTagString
            );

            this.getTags();
            this.showAddTagModal = false;
            this.customTagString = '';
            this.showCustomTag = false;
        },

        askToRemoveTag(tag) {
            this.tagToRemove = tag;
            this.showRemoveTagModal = true;
        },

        async removeTag() {
            this.isDeletingTag = true;

            try {
                await tags.removeTagFromGroup(
                    this.tenantId,
                    this.orgId,
                    'Incident',
                    this.incidentId,
                    this.tagToRemove.provider,
                    this.tagToRemove.type,
                    this.tagToRemove.providersId
                );
                this.getTags();
                this.$message.success("Tag removed successfully");
            } catch (e) {
                console.log(e);
                this.$message.error("Error removing tag");
            } finally {
                this.tagToRemove = null;
                this.showRemoveTagModal = false;
                this.isDeletingTag = false;
            }
        }
    }
}
</script>

<style scoped lang="scss">
.add-tag-wrapper {
    margin-top: 15px;
}

.tag-wrapper {
    margin-bottom: 8px;

    .ant-tag {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>