import axios from 'axios';

export default {
    getTagProviders(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/orgs/' + orgId + '/tags/providers');
    },

    getTags(tenantId, orgId, provider) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/orgs/' + orgId + '/tags?provider=' + provider);
    },

    getTagsForGroup(tenantId, orgId, groupType, groupId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/orgs/' + orgId + '/tags/groups/' + groupType + '/' + groupId);
    },

    addTagToGroup(tenantId, orgId, groupType, groupId, provider, type, providersId, description) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/orgs/' + orgId + '/tags/groups/' + groupType + '/' + groupId, {
            provider,
            type,
            providersId,
            description
        });
    },

    removeTagFromGroup(tenantId, orgId, groupType, groupId, tagsProvider, tagType, tagId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/orgs/' + orgId + '/tags/groups/' + groupType + '/' + groupId + '/' + tagsProvider + '/' + tagType + '/' + tagId);
    }
}
